<script>
import { server } from '@/const';
import { destroyToken, getToken } from '@/shared/services/jwt.service';
import Notify from '@/shared/utils/notify';
import AddCategoryModel from '@/modules/tags/add-category-model';
import BaseButton from '@/shared/components/BaseButton.vue';
import i18n from '../plugins/vue-i18n';

const { fields } = AddCategoryModel;
export default {
  name: 'AddCategory',
  components: {
    BaseButton,
  },
  props: {
    category: {
      type: Object,
    },
  },
  data() {
    return {
      fields,
      error: '',
      model: {},
      categoryId: 0,
    };
  },
  methods: {
    openAddCategory() {
      const addFacultyWindow = document.querySelector('.add-item2');
      addFacultyWindow.classList.toggle('show');
      if (addFacultyWindow.classList.value.includes('hide')) {
        addFacultyWindow.classList.toggle('hide');
      } else {
        setTimeout(() => {
          addFacultyWindow.classList.toggle('hide');
        }, 300);
      }
    },
    addCategoryFunc() {
      this.openAddCategory();
      let addCatPath = '';
      const data = new FormData();
      if (parseInt(this.categoryId, 10) !== 0) {
        addCatPath = '/WebEditTagCategory.php';
        data.append('id', this.categoryId);
      } else {
        addCatPath = '/WebAddTagCategory.php';
      }
      data.append('name', this.model.category_name ? this.model.category_name : '');
      fetch(server + addCatPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      }).then((response) => response.json()).then((json) => {
        if (json.status === 'error') {
          if (json.error.includes('Wrong token')) {
            this.$router.push({ name: 'login' });
            destroyToken();
          } else {
            Notify('warning', json.error);
          }
        }
        if (json.status === 'ok') {
          Notify('success', i18n.t('GENERAL.SAVED_SUCCESSFULLY'));
          this.$router.go({ name: 'categories' });
        }
      });
    },
    deleteCategory(id) {
      const deleteUserPath = '/WebRemoveTagCategory.php';
      const data = new FormData();
      data.append('id', id);

      fetch(server + deleteUserPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            if (json.error.includes('Wrong token')) {
              this.$router.push({ name: 'login' });
              destroyToken();
            } else {
              Notify('warning', json.error);
            }
          }
          if (json.status === 'ok') {
            this.$router.go({ name: 'categories' });
          }
        });
    },
  },
  beforeMount() {
    if (this.category) {
      this.categoryId = this.category.id ?? 0;
      this.model.category_name = this.category.name;
    } else {
      this.categoryId = 0;
      this.model.category_name = '';
    }
  },
};
</script>
<template>
  <div class="add-item2">
    <div class="add-item-left" v-on:click="openAddCategory">
    </div>
    <div class="add-item-window">
      <form class="form" v-on:submit.prevent="addCategoryFunc(1)"
            id="add_dep_form" style="width: 100%;">

        <div style="height: 100vh" :key="this.model.name">
          <div class="menu">
            <unicon name="multiply" fill="#828282" height="24" width="24"
                    v-on:click="openAddCategory"/>
            <div class="title">
              {{ this.categoryId !== 0 ? this.model.category_name : $t('TAGS.ADD_CATEGORY') }}
            </div>
            <BaseButton
              class="float-end"
              :variant="'primary'"
              type="submit">
              <unicon fill="white" height="16" width="16" name="save"/>
              {{ $t('GENERAL.SAVE') }}
            </BaseButton>
          </div>
          <div class="content">
            <b-row>
              <b-col>
                <div class="title-2-bold-20 basic-2">
                  {{ $t('GENERAL.BASIC_INFO') }}
                </div>
              </b-col>
            </b-row>
            <div  style="display: inline-flex;width: 100%;padding: 0 24px">
              <div style="width: -webkit-fill-available;">
                <div class="custom-input">
                  <label>
                    {{ fields.category_name.label }}</label>
                  <input type="text"
                         :id="fields.category_name.name"
                         v-model="model[fields.category_name.name]"
                         :placeholder="fields.category_name.label"
                         required
                         maxlength="200"
                  >
                </div>
              </div>
            </div>
            <b-row>
              <b-col class="col" md="12" v-if="categoryId !== 0" style="margin-top: 20px">
                <BaseButton
                  :variant="'danger'"
                  :callback="deleteCategory"
                  :callback-params="categoryId"
                  style="width: 100%"
                >

                  <unicon name="trash" fill="#FFFFFF" height="16" width="16"/>
                  {{ $t('GENERAL.DELETE') }}
                </BaseButton>
              </b-col>
            </b-row>
          </div>
        </div>
      </form>

    </div>
  </div>
</template>

<style>
</style>
