<script>

import { isObjectEmpty } from '@/shared/utils/globalMethods';
import BaseButton from '@/shared/components/BaseButton.vue';

export default {
  name: 'TagCard',
  props: {
    tagInfo: {
      type: Object,
    },
    index: {
      type: Number,
      required: false,
    },
    showCards: Function,
    edit: Function,
    editParams: {
      type: Object,
    },
    addCardWithAi: Function,
    addCard: Function,
    callbackParams2: {
      type: String,
    },
  },
  components: {
    BaseButton,
  },
  methods: {
    clickEdit() {
      if (this.edit) {
        if (!isObjectEmpty(this.editParams)) {
          this.edit(this.editParams);
        } else {
          this.edit();
        }
      }
    },
    clickShow() {
      if (this.showCards) {
        if (!isObjectEmpty(this.editParams)) {
          this.showCards(this.editParams);
        } else {
          this.showCards();
        }
      }
    },
    clickAddCardWithAi(e) {
      e.preventDefault();
      if (this.addCardWithAi) {
        if (!isObjectEmpty(this.callbackParams2)) {
          this.addCardWithAi(this.callbackParams2);
        } else {
          this.addCardWithAi();
        }
      }
    },
    clickAddCard(e) {
      e.preventDefault();
      if (this.addCard) {
        if (!isObjectEmpty(this.callbackParams2)) {
          this.addCard(this.callbackParams2);
        } else {
          this.addCard();
        }
      }
    },
  },
};
</script>

<template>
  <div class="container-card-tag">
    <b-row>
      <b-col md="12" style="padding: 0">
        <div>
          <div class="d-flex">
            <div class="icon me-3">
              <unicon name="subject" fill="#1694D0" height="20" width="20"/>
            </div>
            <div style="width: 100%">
              <div style="margin-top: -9px;">
                <div class="d-inline-flex">
                  <div class="contains" v-on:click="clickShow">
                    <unicon name="play-circle" fill="#F48C00" height="16" width="16"/>
                    <p style="border-bottom: 1px solid #F48C00; display: inline-flex; margin: 0">
                      {{ tagInfo.questions_amount }} {{ $t('GENERAL.CARDS_LOWERCASE') }}</p>
                  </div>
                </div>
              </div>
              <div>
                <div class="d-inline-flex headline-bold-16 basic-1"
                     style="margin-bottom: 8px;height: 19px;overflow: hidden">
                  {{ tagInfo.title }}
                </div>
              </div>
            </div>
            <div v-on:click="clickEdit" class="ms-3">
              <BaseButton variant="info" style="float: right; width: max-content"
              >
                <unicon name="arrow-right" width="20" height="20"
                        style="margin-top: -2px" fill="#1694D0"/>
              </BaseButton>
            </div>
          </div>
        </div>
      </b-col>
      <b-col md="12" style="padding: 8px 0 0 0">
        <div class="separator">

        </div>
      </b-col>
      <b-col md="6" v-on:click="clickAddCardWithAi" style="padding: 0">
        <BaseButton variant="info" style="width: 100%">
          <unicon name="robot" width="22" height="22" style="margin-top: -4px"
                  fill="#828282"/>
          {{ $t('TAGS.CREATE_WITH_AI') }}
        </BaseButton>
      </b-col>
      <b-col md="6" v-on:click="clickAddCard" style="padding-right: 0">
        <BaseButton variant="info" style="width: 100%">
          <img src="/media/cards.svg" alt="" style="width: 20px"/>

          {{ $t('TAGS.ADD_LEARNING_CARD') }}
        </BaseButton>
      </b-col>
    </b-row>
  </div>
</template>

<style lang="scss">
.separator {
  height: 1px;
  border: 1px solid #D9DBE3;
  margin-bottom: 12px;
}
.container-card-tag {
  margin-bottom: 20px;
  height: 135px;
  cursor: pointer;
  background: #FFFFFF;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 16px;
  width: 100%;
}
.container-card-tag .icon {
  width: 38px;
  height: 38px;
  position: relative;
  overflow: hidden;
  padding: 9px;
  background: #F2F2F2;
  border-radius: 8px;
}
.container-card-tag .icon svg {
  margin-top: -4px;
}

.container-card-tag .contains {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #F48C00;
}

.container-card-tag .edit-test{
  margin: 35px 0;
  max-width: 200px;
  justify-content: center;
  display: inline-flex;
  align-items: center;
  width: fit-content;
  padding-right: 20px;
  cursor: pointer;
}
</style>
