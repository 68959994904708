import i18n from '@/shared/plugins/vue-i18n';
import GenericModel from '@/shared/model/generic-model';
import StringField from '@/shared/fields/string-field';

const label = (name) => i18n.t(`TAGS.FIELDS.${name.toUpperCase()}`);

const fields = {
  category: new StringField('category', label('category'), {
    required: true,
  }),
  departament: new Array([]),
  group: new Array([]),
  tag_name: new StringField('tag_name', label('tag_name'), {
    required: true,
  }),
  your_url: new StringField('your_url', label('your_url'), {
    required: true,
  }),
  dateRange: new Array([new Date(), new Date()]),
  question_type: new StringField('question_type', label('question_type'), {
    required: true,
  }),
  question_title: new StringField('question_title', label('question_title'), {
    required: true,
  }),
  answer: new StringField('answer', label('answer'), {
    required: true,
  }),
};

export default class AddTagsModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
