<script>
import { server } from '@/const';
import { getToken, destroyToken } from '@/shared/services/jwt.service';
import Notify from '@/shared/utils/notify';
import BaseButton from '@/shared/components/BaseButton.vue';
import 'vue-simple-accordion/dist/vue-simple-accordion.css';
import Recorder from './Recorder.vue';

const store = {
  state: {
    questions: [],
    currentQuestion: null,
    currentQuestionIndex: null,
  },
};
export default {
  name: 'ShowCardsForTag',
  components: {
    Recorder,
    BaseButton,
  },
  props: {
    tagId: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  data() {
    return {
      store,
      category_name: '',
      tag_name: '',
      loading: true,
      showQuestion: true,
      showAnswer: false,
      answer: null,
      id: null,
      answerSelected: null,
      answerText: '',
      isRecording: false,
    };
  },
  methods: {
    showAnswerFunction() {
      const showWindow = document.querySelector('.show-cards-modal');
      showWindow.classList.toggle('flipped');
      this.showAnswer = true;
      this.showQuestion = false;
    },
    showNextQuestion() {
      if (store.state.questions.length - 1 === store.state.currentQuestionIndex) {
        this.openShowCard();
      } else {
        const showWindow = document.querySelector('.show-cards-modal');
        showWindow.classList.toggle('flipped');
        store.state.currentQuestionIndex += 1;
        store.state.currentQuestion = store.state.questions[store.state.currentQuestionIndex];
        this.answerText = '';
        this.showAnswer = false;
        this.showQuestion = true;
        this.answerSelected = null;
      }
    },
    openShowCard() {
      const showWindow = document.querySelector('.show-cards');
      showWindow.classList.toggle('show');
      if (showWindow.classList.value.includes('hide')) {
        this.loading = true;
        showWindow.classList.toggle('hide');
      } else {
        store.state.currentQuestionIndex = null;
        store.state.currentQuestion = null;
        this.showAnswer = false;
        this.showQuestion = true;
        this.answerSelected = null;
        setTimeout(() => {
          this.loading = true;
          showWindow.classList.toggle('hide');
        }, 300);
      }
    },
    getTag(id) {
      this.id = id;
      const getTagPath = '/WebGetTagDetails.php';
      const data = new FormData();
      data.append('id', id);

      fetch(server + getTagPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      }).then((response) => response.json()).then((json) => {
        if (json.status === 'error') {
          if (json.error.includes('Wrong token')) {
            this.$router.push({ name: 'login' });
            destroyToken();
          } else {
            Notify('warning', json.error);
          }
        }
        if (json.tag !== '') {
          this.tag_name = json.tag;
          this.category_name = json.categories[0].name;
        }
      });
      const x = new FormData();
      x.append('tag_id', id);
      fetch('https://api.edneos.com/api/WebListQuestions.php', {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: x,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.questions && json.questions.length > 0) {
            store.state.questions = json.questions;
            /* eslint-disable */
            store.state.currentQuestion = json.questions[0];
            store.state.currentQuestionIndex = 0;
          }
        });

      this.loading = false
    },
  },
  beforeMount() {
    this.loading = true
    this.tag_name = ''
    this.category_name = ''

    this.showQuestion = true;
    this.showAnswer = false;
    this.answer = null;
    store.state.questions = [];
    if (this.tagId !== 0) {
      this.getTag(this.tagId);
    }
  },
  beforeUpdate() {
    if (this.tagId !== 0 && this.tagId !== this.id) {
      this.loading = true
      this.tag_name = ''
      this.category_name = ''
      this.showQuestion = true;
      this.showAnswer = false;
      this.answer = null;
      store.state.questions = [];
      this.getTag(this.tagId);
    }
  },
};
</script>
<template>
  <div class="show-cards-modal">
    <div v-if="this.loading === false && this.tag_name !== '' && this.showQuestion" class="front">
        <div v-if="store.state.currentQuestion.type === 'flash'" class="h-100" style="margin-top: -20px">
          <div class="close">
            <unicon name="multiply" width="16" height="16" fill="#000000" v-on:click="openShowCard"/>
          </div>
          <div class="progress-container">
            <div class="progress-title basic-2">
              {{ $t('TAGS.ELEMENTAR_CARD_PROGRESS') }}
            </div>
            <div>
              <div style="display: block; width: calc(100% - 40px)">
                <b-progress :max="store.state.questions.length" >
                  <b-progress-bar :value="store.state.currentQuestionIndex + 1" variant="success">
                  </b-progress-bar>
                </b-progress>
              </div>
              <div class="progress-info">
                {{store.state.currentQuestionIndex+1}}/{{store.state.questions.length}}
              </div>
            </div>

          </div>
          <div style="margin-top: 20px">
            <div class="basic-3 caption-caps-med-12">

              {{ $t('GENERAL.QUESTION') }}

            </div>



            <div class="basic-1 title-2-bold-20 mt-1">
              {{store.state.currentQuestion.title}}
            </div>
          </div>
          <div class="image">
            <img class="question-image" v-if="store.state.currentQuestion.image" :src="store.state.currentQuestion.image">
          </div>
          <div class="bottom-container">
            <div>
              <div class="category-container caption-1-reg-13">
                <unicon name="bookmark" width="16" height="16" fill="#1694D0"/>
                {{this.tag_name}}
              </div>
            </div>
            <div class="caption-caps-med-12 basic-3 mt-2">
              {{this.tag_name}}
            </div>
            <div class="d-flex justify-content-center">
              <BaseButton style="width: 50%;margin-top: 20px" :callback="showAnswerFunction"
                          :callback-params="1">
                {{ $t('TAGS.VIEW_ANSWER') }}
              </BaseButton>
            </div>
          </div>
        </div>
        <div v-if="store.state.currentQuestion.type === 'options'" class="h-100" style="margin-top: -20px">
          <div class="close">
            <unicon name="multiply" width="16" height="16" fill="#000000" v-on:click="openShowCard"/>
          </div>
          <div class="progress-container">
            <div class="progress-title basic-2">
              {{ $t('TAGS.ELEMENTAR_CARD_PROGRESS') }}
            </div>

            <div>
              <div style="display: block; width: calc(100% - 40px)">
                <b-progress :max="store.state.questions.length" >
                  <b-progress-bar :value="store.state.currentQuestionIndex + 1" variant="success">
                  </b-progress-bar>
                </b-progress>
              </div>
              <div class="progress-info">
                {{store.state.currentQuestionIndex+1}}/{{store.state.questions.length}}
              </div>
            </div>

          </div>
          <div style="margin-top: 20px">
            <div class="basic-3 caption-caps-med-12">
              {{ $t('GENERAL.QUESTION') }}
            </div>
            <div class="basic-1 title-2-bold-20 mt-1">
              {{store.state.currentQuestion.title}}
            </div>
          </div>
          <div>
            <div v-for="(answer, i) in store.state.currentQuestion.answers" class="subhead-reg-14" style="color: #000000">
              <div class="w-100 d-flex">

                <input type="radio"
                       v-on:click="answerSelected = answer"
                       :checked="answerSelected === answer" class="checkbox input-radio"/>
                {{answer.answer}}
              </div>
              <div v-if="i !== (store.state.currentQuestion.answers.length - 1)" class="form-separator" style="height: 1px;margin: 8px 0">
              </div>
            </div>
          </div>
<!--          <div class="image">
            <img class="question-image" v-if="store.state.currentQuestion.image" :src="store.state.currentQuestion.image">
          </div>-->
          <div class="bottom-container">
            <div>
              <div class="category-container caption-1-reg-13">
                <unicon name="bookmark" width="16" height="16" fill="#1694D0"/>
                {{this.tag_name}}
              </div>
            </div>
            <div class="caption-caps-med-12 basic-3 mt-2">
              {{this.tag_name}}
            </div>
            <div class="d-flex justify-content-center">
              <BaseButton style="width: 50%;margin-top: 20px" :callback="showAnswerFunction"
                          :callback-params="1" :disabled="answerSelected === null">
                {{ $t('TAGS.SEND_ANSWER') }}
              </BaseButton>
            </div>
          </div>
        </div>
        <div v-if="store.state.currentQuestion.type === 'voice'" class="h-100" style="margin-top: -20px">
          <div class="close">
            <unicon name="multiply" width="16" height="16" fill="#000000" v-on:click="openShowCard"/>
          </div>
          <div class="progress-container">
            <div class="progress-title basic-2">
              {{ $t('TAGS.ELEMENTAR_CARD_PROGRESS') }}
            </div>

            <div>
              <div style="display: block; width: calc(100% - 40px)">
                <b-progress :max="store.state.questions.length" >
                  <b-progress-bar :value="store.state.currentQuestionIndex + 1" variant="success">
                  </b-progress-bar>
                </b-progress>
              </div>
              <div class="progress-info">
                {{store.state.currentQuestionIndex+1}}/{{store.state.questions.length}}
              </div>
            </div>

          </div>
          <div style="margin-top: 20px">
            <div class="basic-3 caption-caps-med-12">

              {{ $t('GENERAL.QUESTION') }}
            </div>

            <div class="basic-1 title-2-bold-20 mt-1">
              {{store.state.currentQuestion.title}}
            </div>
          </div>

<!--          <div class="image">
            <img class="question-image" v-if="store.state.currentQuestion.image" :src="store.state.currentQuestion.image">
          </div>-->
          <div class="bottom-container">
            <div class="custom-input" style="width: 100%" v-if="this.isRecording === false && this.answerText !== ''">
              <textarea type="text" style="margin-bottom: -20px"
                        :value="this.answerText"
              />
              <div class="">
                <BaseButton style="width: 20%;margin-top: 20px; margin-right: 10px" :callback="() => {
                  this.answerText = '';
                }" variant="light"
                            :callback-params="1">
                  {{ $t('GENERAL.BACK') }}
                </BaseButton>
                <BaseButton style="width: 50%;margin-top: 20px" :callback="showAnswerFunction"
                            :callback-params="1">
                  {{ $t('TAGS.SEND_ANSWER') }}
                </BaseButton>
              </div>
            </div>
            <div v-else>
              <div>
                <div class="category-container caption-1-reg-13">
                  <unicon name="bookmark" width="16" height="16" fill="#1694D0"/>
                  {{this.tag_name}}
                </div>
              </div>
              <div class="caption-caps-med-12 basic-3 mt-2">
                {{this.tag_name}}
              </div>
              <div class="form-separator" style="height: 1px;margin: 8px 0">
              </div>
              <div class="basic-1 caption-caps-med-12">
                Ești pregătit să răspunzi? Apasă pe acest buton și oferă-ne răspunsul tău vocal
              </div>
              <div class="d-flex justify-content-center" style="width: 100%">
                <Recorder  :onChange="(value,rec) => {
                          this.answerText = value
                          this.isRecording = rec
                        }"/>
              </div>
            </div>
          </div>
        </div>
        <div v-if="store.state.currentQuestion.type === 'text'" class="h-100" style="margin-top: -20px">
          <div class="close">
            <unicon name="multiply" width="16" height="16" fill="#000000" v-on:click="openShowCard"/>
          </div>
          <div class="progress-container">
            <div class="progress-title basic-2">
              {{ $t('TAGS.ELEMENTAR_CARD_PROGRESS') }}
            </div>

            <div>
              <div style="display: block; width: calc(100% - 40px)">
                <b-progress :max="store.state.questions.length" >
                  <b-progress-bar :value="store.state.currentQuestionIndex + 1" variant="success">
                  </b-progress-bar>
                </b-progress>
              </div>
              <div class="progress-info">
                {{store.state.currentQuestionIndex+1}}/{{store.state.questions.length}}
              </div>
            </div>

          </div>
          <div style="margin-top: 20px">
            <div class="basic-3 caption-caps-med-12">

              {{ $t('GENERAL.QUESTION') }}

            </div>



            <div class="basic-1 title-2-bold-20 mt-1">
              {{store.state.currentQuestion.title}}
            </div>
          </div>
          <div class="image">
            <img class="question-image" v-if="store.state.currentQuestion.image" :src="store.state.currentQuestion.image">
          </div>

          <div class="bottom-container">
            <div>
              <div class="category-container caption-1-reg-13">
                <unicon name="bookmark" width="16" height="16" fill="#1694D0"/>
                {{ this.tag_name }}
              </div>
            </div>
            <div class="caption-caps-med-12 basic-3 mt-2">
              {{ this.tag_name }}
            </div>
            <div class="custom-input">
              <input type="text"
                     :value="this.answerText"
                     @change="(e) => {
                       this.answerText = e.target.value
                     }"
                     required
                     maxlength="200"
                     :placeholder="$t('TAGS.FIELDS.ANSWER')"
              >
              <BaseButton
                  style="position: absolute; right: 44px; top: 12px;background: transparent;
                padding: 0;border: 0"
                  class="float-end"
                  :variant="'link'"
                  :callback="showAnswerFunction"
                  :callback-params="1"
                  type="button">
                <unicon fill="#1694D0" height="24" width="24" name="arrow-right"/>
              </BaseButton>
            </div>


          </div>
        </div>
      </div>
    <div v-if="this.loading === false && this.tag_name !== '' && this.showAnswer" class="back">
        <div v-if="store.state.currentQuestion.type === 'flash'" class="h-100" style="margin-top: -20px">
          <div class="close">
            <unicon name="multiply" width="16" height="16" fill="#000000" v-on:click="openShowCard"/>
          </div>
          <div class="progress-container">
            <div class="progress-title basic-2">
              {{ $t('TAGS.ELEMENTAR_CARD_PROGRESS') }}
            </div>

            <div>
              <div style="display: block; width: calc(100% - 40px)">
                <b-progress :max="store.state.questions.length" >
                  <b-progress-bar :value="store.state.currentQuestionIndex + 1" variant="success">
                  </b-progress-bar>
                </b-progress>
              </div>
              <div class="progress-info">
                {{store.state.currentQuestionIndex+1}}/{{store.state.questions.length}}
              </div>
            </div>

          </div>
          <div style="margin-top: 20px">
            <div class="basic-3 caption-caps-med-12">

              {{ $t('TAGS.ANSWER') }}

            </div>
            <div class="basic-1 title-2-bold-20 mt-1">
              {{store.state.currentQuestion.answers.find(item => item.right === true).answer}}
            </div>
          </div>
          <div class="bottom-container">
            <div>
              <div class="category-container caption-1-reg-13">
                <unicon name="bookmark" width="16" height="16" fill="#1694D0"/>
                {{this.tag_name}}
              </div>
            </div>
            <div class="caption-caps-med-12 basic-3 mt-2">
              {{this.tag_name}}
            </div>
            <div class="form-separator" style="height: 1px;margin: 8px 0">
            </div>

            <div class="basic-1 caption-2-reg-12">
              {{ $t('TAGS.WHAT_THINK_DIFFICULT_LEVEL_FOR_CARD') }}
            </div>
            <div class="d-flex justify-content-center">
              <BaseButton style="width: 48%;margin-top: 20px; margin-right: 4%" :callback="showNextQuestion"
                          variant="success"
                          :callback-params="1">
                {{ $t('TAGS.EASY') }}
              </BaseButton>
              <BaseButton style="width: 48%;margin-top: 20px" :callback="showNextQuestion"
                          variant="warning"
                          :callback-params="1">
                {{ $t('TAGS.HARD') }}
              </BaseButton>
            </div>
          </div>
        </div>
        <div v-if="(store.state.currentQuestion.type === 'options' && answerSelected && answerSelected.right === true) ||
          ((store.state.currentQuestion.type === 'voice' || store.state.currentQuestion.type === 'text') &&
           answerText === store.state.currentQuestion.answers[0].answer)" class="h-100" style="margin-top: -20px">
          <div class="close">
            <unicon name="multiply" width="16" height="16" fill="#000000" v-on:click="openShowCard"/>
          </div>
          <div class="progress-container">
            <div class="progress-title basic-2">
              {{ $t('TAGS.ELEMENTAR_CARD_PROGRESS') }}
            </div>

            <div>
              <div style="display: block; width: calc(100% - 40px)">
                <b-progress :max="store.state.questions.length" >
                  <b-progress-bar :value="store.state.currentQuestionIndex + 1" variant="success">
                  </b-progress-bar>
                </b-progress>
              </div>
              <div class="progress-info">
                {{store.state.currentQuestionIndex+1}}/{{store.state.questions.length}}
              </div>
            </div>

          </div>
          <div style="margin-top: 160px;">
            <div class="title-2-bold-20 basic-1 text-center mb-1">
              {{ $t('TAGS.FANTASTIC') }}</div>
            <div class="caption-2-reg-12 basic-3 text-center">
              {{ $t('TAGS.YOU_ANSWERED_CORRECTLY') }}</div>
          </div>
          <div class="bottom-container">

            <div class="d-flex justify-content-center">
              <BaseButton style="width: 50%;margin-top: 20px" :callback="showNextQuestion"
                          :callback-params="1" variant="secondary">
                {{ $t('TAGS.CONTINUE') }}
              </BaseButton>
            </div>
          </div>
        </div>
        <div v-if="(store.state.currentQuestion.type === 'options' && answerSelected && answerSelected.right === false) ||
          (store.state.currentQuestion.type === 'voice' && answerText !== store.state.currentQuestion.answers[0].answer)" class="h-100" style="margin-top: -20px">
          <div class="close">
            <unicon name="multiply" width="16" height="16" fill="#000000" v-on:click="openShowCard"/>
          </div>
          <div class="progress-container">
            <div class="progress-title basic-2">
              {{ $t('TAGS.ELEMENTAR_CARD_PROGRESS') }}
            </div>

            <div>
              <div style="display: block; width: calc(100% - 40px)">
                <b-progress :max="store.state.questions.length" >
                  <b-progress-bar :value="store.state.currentQuestionIndex + 1" variant="success">
                  </b-progress-bar>
                </b-progress>
              </div>
              <div class="progress-info">
                {{store.state.currentQuestionIndex+1}}/{{store.state.questions.length}}
              </div>
            </div>

          </div>
          <div style="margin-top: 160px;">
            <div class="title-2-bold-20 basic-1 text-center mb-1">
              {{ $t('TAGS.WITH_REGRET') }}</div>
            <div class="caption-2-reg-12 basic-3 text-center">
              {{ $t('TAGS.YOU_ANSWERED_WRONG') }}</div>
          </div>
          <div class="bottom-container">
            <div class="caption-2-reg-12 orange text-center">
              {{ $t('TAGS.THE_CORRECT_ANSWER_IS') }}</div>

            <div class="form-separator" style="height: 1px;margin: 8px 0">
            </div>
            <div class="caption-2-reg-12 basic-1">
              {{store.state.currentQuestion.answers.find(item => item.right === true).answer}}
            </div>
            <div class="d-flex justify-content-center">
              <BaseButton style="width: 50%;margin-top: 20px" :callback="showNextQuestion"
                          :callback-params="1" variant="secondary">
                {{ $t('TAGS.CONTINUE') }}
              </BaseButton>
            </div>
          </div>
        </div>

      </div>
  </div>
</template>

<style>
.show-cards.hide{
  .show-cards{
    display: none;
  }
  display: none;
}
.show-cards.show{
  display: flex;
}
.show-cards{
  position: absolute;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  vertical-align: center;
  align-items: center;
}
.show-cards-modal{
  background-color: #FFFFFF;
  width: 500px;
  max-width: 100vw;
  min-height: 500px;
  max-height: 500px;
  overflow: hidden;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 24px;

  -webkit-transition: -webkit-transform 0.4s;
  -moz-transition: -moz-transform 0.4s;
  -o-transition: -o-transform 0.4s;
  transition: transform 0.4s;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  &.flipped{
    -webkit-transform: rotateY( 180deg );
    -moz-transform: rotateY( 180deg );
    -o-transform: rotateY( 180deg );
    transform: rotateY( 180deg );
  }
  .front,
  .back {
    display: block;
    height: 100%;
    width: 100%;
    line-height: 60px;
    color: white;
    position: absolute;
    left: 0;
    top: 0;
    padding: 24px;
  }

  .back {
    -webkit-transform: rotateY( -180deg );
    -moz-transform: rotateY( -180deg );
    -o-transform: rotateY( -180deg );
    transform: rotateY( -180deg );
  }
  .back .close{
    position: absolute;
    top: 12px;
    right: 12px;
  }
  .front .close{
    position: absolute;
    top: -12px;
    right: 12px;
  }

  .progress-container{
    .progress-title{
      margin-top: 20px;
      font-family: 'IBM Plex Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      text-transform: uppercase;
    }

    .progress {
      margin-top: 12px;
      padding: 0px;
      gap: 28px;

      height: 9px;

      background: rgba(22, 148, 208, 0.1);
      border-radius: 8px;
      .bg-success{
        height: 9px;
        background-color: #1694D0!important;
        border-radius: 8px;
      }
    }
    .progress-info{
      /* subtext / regular */
      font-family: 'IBM Plex Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 140%;
      /* identical to box height, or 17px */
      text-align: right;
      letter-spacing: 0.25px;
      /* transparent blacks / 60 */
      color: rgba(0, 0, 0, 0.6);
      margin-top: -12px;

    }

  }

  .bottom-container{
    position: absolute;
    bottom: 24px;
    width: calc(100% - 48px);
  }
  .category-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 8px 4px 4px;
    gap: 8px;
    background: rgba(22, 148, 208, 0.1);
    border-radius: 8px;
    width: fit-content;
    color: #1694D0;
  }
  .input-radio{
    border-radius: 50%;
    margin-right: 8px;
    width: 16px;
    height: 16px;
  }
  .recorder-container{
    margin-top: 20px;
    width: 50%!important;
    position: unset;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    display: flex;
    .unicon{
      margin-top: -36px;
    }
  }
  .image{
    height: 180px;
    display: flex;
    justify-content: center;
    margin: auto;

    .question-image{
      border-radius: 8px;
      height: -webkit-fill-available;
    }
  }


}
</style>
