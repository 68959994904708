import i18n from '@/shared/plugins/vue-i18n';
import GenericModel from '@/shared/model/generic-model';
import StringField from '@/shared/fields/string-field';

const label = (name) => i18n.t(`TAGS.FIELDS.${name.toUpperCase()}`);

const fields = {
  category_name: new StringField('category_name', label('category_name'), {
    required: true,
  }),
};

export default class AddCategoryModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
